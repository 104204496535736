<template>
  <div>
    <token-search
      :openSearch="showTokensList"
      :selectedToken="selectedToken"
      @tokenSelected="setupToken($event), (showTokensList = false)"
      @close="showTokensList = false"
    />

    <new-stake
      v-if="showStakeWindow"
      :tokenAddress="tokenAddress"
      :tokenDecimals="tokenDecimals"
      :showStakeWindow="showStakeWindow"
      :stakeAmount="stakeAmount"
      :tokenContract="stakeTokenContract"
      :tokenBalance="stakeTokenBalance"
      :pool="poolData"
      @poolLimitExceed="stakeAmount = $event"
      @poolLimitReached="showPoolLimitReached=true, stakeAmount=$event"
      @waitStake="showPendingStake = true"
      @waitApprove="showPendingApprove = true"
      @showError="showError = true"
      @close="
        (showPendingStake = false),
          (showPendingApprove = false),
          (showSuccessApprove = false),
          (showSuccessStake = false)
      "
      @approveSuccess="showSuccessApprove = true"
      @stakeSuccess="showSuccessStake = true, redirectToStake()"
      @closeStakeWindow="showStakeWindow = false"
    />

    <transaction-status
      :stakeAmount="stakeAmount"
      :tokenName="selectedToken"
      :showPendingStake="showPendingStake"
      :showPendingApprove="showPendingApprove"
      :showSuccessStake="showSuccessStake"
      :showSuccessApprove="showSuccessApprove"
      :showError="showError"
      :showPoolLimitReached="showPoolLimitReached"
      @close="
        (showPendingStake = false),
          (showSuccessStake = false),
          (showPendingApprove = false),
          (showSuccessApprove = false),
          (showError = false),
          (showPoolLimitReached = false)
      "
    />
    <loader v-if="showLoader" />
    <show-add-info-modal v-if="showAddInfoModal" @close="showAddInfoModal = false" :tokenAddress="getFarmTokens(poolData) ? getFarmTokens(poolData)[0].address : ''" />
    <main class="cabinet">
      <div class="container">
        <div class="cabinet-header">
          <div class="h1">{{lang.get("CABINET_WELCOME_TITLE")}}</div>
          <cabinet-navigation />
        </div>
        <div class="wrapper-content">
          <div class="card card_input sidebar">
            <div class="block_sidebar">
              <div class="block">
                <div class="block_title">
                  <div class="count">1</div>
                  <div class="title">{{lang.get("CHOOSE_YOUR_TOKEN")}}</div>
                </div>
                <div class="input" @click="showTokensList = true">
                  <div v-if="!selectedToken" class="inline_input">
                    <button
                      class="button-select_token"
                      data-cy="button-select_token"
                    >
                      {{lang.get("CHOOSE_YOUR_TOKEN")}}
                    </button>
                    <i class="i-arrow"></i>
                  </div>
                  <div v-else class="inline_input">
                    <div class="token_img">
                      <div :class="selectedToken"></div>
                    </div>
                    <button
                      class="button-select_token"
                      data-cy="button-select_token-2"
                    >
                      {{ tokenTicker(selectedToken) }}
                    </button>
                    <i class="i-arrow"></i>
                  </div>

                  <!-- <div class="token_img"><div class="token-a"></div></div>
                    <input v-if="!selectedToken" placeholder="Choose your token">
                    <input v-else :value="selectedToken">
                  <i class="i-arrow"></i> -->
                </div>
                <div class="addinfo">
                  <i class="i-attention"></i>
                  <div style="display:inline-block">
                    <span style="font-weight: bold">Important:</span> This is a LP tokens' pool! To participate you need to have
                  <a @click="showAddInfoModal = true"> Liquidity Tokens</a>
                  </div>
                </div>
              </div>
              <div class="block">
                <div class="block_title">
                  <div class="count">2</div>
                  <div class="title">{{lang.get("ENTER_AMOUNT")}}</div>
                </div>
                <div class="input" :class="{active: showInputError}" id="number">
                  <input
                    @input="checkBalance(), getTokensRates()"
                    type="number"
                    min="0"
                    placeholder="Enter the amount"
                    v-model="stakeAmount"
                    
                  />
                  <button
                    @click="setMaxValue()"
                    class="button button_max"
                    data-cy="button_max"
                  >
                    {{lang.get("MAX")}}
                  </button>
                </div>
                <div class="addinfo">
                  <i class="i-balance"></i>
                  <span>{{lang.get("WALLET_BALANCE")}}&nbsp;{{stakeTokenBalance}}</span>
                </div>
                <div v-if="lowBalance" class="inform">
                  <i class="i-attention"></i>
                  <div>{{lang.get("BALANCE_INSUF_WARNING")}}</div>
                </div>
                <div v-if="incorrectAmount" class="inform">
                  <i class="i-attention"></i>
                  <div>{{lang.get("ZERO_AMOUNT")}}</div>
                </div>
                <div v-if="showPoolLimitExceedError" class="inform">
                  <i class="i-attention"></i>
                  <div>{{lang.get("POOL_LIMIT_EXCEED")}}&nbsp;{{maxStake}}</div>
                </div>
                <div v-if="minStakeAmount" class="inform">
                  <i class="i-attention"></i>
                  <div>{{lang.get("MIN_STAKE_AMOUNT")}}&nbsp;{{poolData.userMinStake}}</div>
                </div>
              </div>
            </div>
            <button
              data-cy="button_submit-input-pool"
              @click="findPools(), getTokensRates()"
              class="button button_submit-input"
            >
              {{lang.get("SHOW_POOLS")}}
            </button>
          </div>

          <!-- поки користувач не підключив гаманець -->
          <div v-if="showAnimation" class="image">
            <div class="image-wrapper">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>

          </div>
          <!-- SHOW POOL STATS IF NOT WALLET OR WALLET NOT CONNECTED -->
          <!-- <div v-if="showAllPoolStatsModal && (!currentAddress || currentAddress == '0x0000000000000000000000000000000000000000')" class="wrapper_pool">
            <ul class="modal-list_pools"  data-cy="list-select-wallet">
                <li v-for="pool in poolsNoWallet" :key="pool.address" class="wrapper_pool">
                     <div class="list_pools-item card_pool">
                        <div>
                        <div class="h2">RING#2 | {{tokenTicker(pool.name)}}
                        </div>
                        <div class="token">
                          <div class="token_img"><div :class="pool.name"></div></div>
                          <div class="token_title">{{tokenTicker(pool.name)}}</div>
                        </div>
                        <div class="pool-block">
                  <div class="description">
                    <div class="title-description">{{lang.get("POOL_STRENGTH")}}</div>
                    <div class="value-description">
                        {{getPoolStrengthNoWallet(pool)}} %
                  </div> 
                  </div>
                  <div class="progress">
                    <div :style="{ width: getPoolStrengthNoWallet(pool) + '%' }"></div>
                  </div>
                        </div>
                        </div>
                        <div>
                            <div class="h2">APY</div>
                            <div class="h1 h1-pool_apy h1-green">{{poolAPYPercentageNoWallet(pool)}}%</div>
                            <div class="addinfo addinfo-end_date">
                              <i class="i-calendar"></i>
                              <span>Ends in:</span>
                              <span>{{poolEndsIn}} days</span>
                            </div>
                            <div  class="pool-reward">
                              <div v-for="farmToken in getFarmTokensForPoolNoWallet(pool)" :key="farmToken.address" class="token_img"><div :class="farmToken.name"></div></div>
                            </div>  
                        </div>
                     </div>
                </li>  
                
              </ul>
          </div> -->

          <!-- коли користувач підключився, але не має пулів -->
          <div v-if="selectPool && currentAddress" class="card card_empty">
            <div class="img"></div>
            <div class="h4">{{lang.get("SELECT_POOL")}}</div>
          </div>

          <div v-if="currentAddress && pools.length && showPools" class="wrapper_pool">
            <div
              v-for="(pool, index) in pools"
              :key="pool.poolVersion"
              class="card card_pool"
            >
              <div>
                <div class="h2 title-with-addinfo">RING#{{Number(pool.poolVersion.split('')[1])+1}} | {{tokenTicker(pool.name)}} 
                  <i  class="i-inform">
                    <div class="block-hover-descr">
                      <div class="hover-descr-title">
                        What are LP Tokens
                      </div>
                      
                      <p>
                        LP (liquidity pool) tokens are the tokens that liquidity providers receive for adding funds to a liquidity pool.
              <!-- <br/>Our liquidity pools currently has four tokens: BRNG, APYS, ROAD, and HGET. -->
              <!-- How to Get LP Tokens -->
              <br/><br/>Become a liquidity provider to start earning LP tokens today! You can use
              <a  target="_blank" :href="`https://pancakeswap.finance/add/BNB/${getFarmTokens(pool) ? getFarmTokens(pool)[0].address : ''}`">PancakeSwap</a> for Binance Smart Chain.
          </p></div>
                  </i>
                </div>
                <div class="token">
                  <div class="token_img"><div :class="pool.name"></div></div>
                  <div class="token_title">{{ tokenTicker(pool.name) }}</div>
                </div>
                <div class="pool-block">
                  <div class="description">
                    <div class="title-description">{{lang.get("POOL_STRENGTH")}}</div>
                    <div class="value-description">
                      {{
                        poolStrength(pool)
                          ? poolStrength(pool)
                          : 0.0
                      }}%
                    </div>
                  </div>
                  <div class="progress">
                    <div :style="{ width: poolStrength(pool) + '%' }"></div>
                  </div>
                </div>
                <div class="pool-block">
                  <div class="description">
                    <div class="title-description title-with-addinfo">
                     <span class="text-left"> {{lang.get("EXPECTED_REWARD")}}</span>
                      <i v-if="poolStrength(pool) < 100" class="i-inform">
                        <div class="block-hover-descr">
                          <div class="hover-descr-title">
                            {{lang.get("EXPECTED_RETURN")}} {{poolEndsIn(pool) > 0 ? poolEndsIn(pool) : 0}}&nbsp;{{lang.get("DAYS")}}
                            <p v-if="pool.referrerStatus" style="color: #17bd63"> ({{lang.get("REFERRER_REWARD")}} +5%)</p>
                          </div>
                          <div
                            v-for="farmToken in pool.farmTokensList"
                            :key="farmToken.address"
                            class="hover-table"
                          >
                            <div class="hover-tr">
                              <div class="hover-td">{{ tokenTicker(farmToken.name) }}</div>
                              <div class="hover-td">
                                {{
                                  Number(farmToken.possibleReward) > 0
                                    ? farmToken.possibleReward.toFixed(4)
                                    : "0.0000"
                                }}
                              </div>
                            </div>
                          </div>
                        </div></i
                      >
                    </div>
                  </div>
                  <div class="pool-reward">
                    <div
                      v-for="farmToken in getFarmTokens(pool)"
                      :key="farmToken.address"
                      class="token_img"
                    >
                      <div :class="farmToken.name"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="h2">APY</div>
                <div class="h1">{{poolAPYPercentage(pool)}}%</div>
                <div class="pool-block">
                  <div class="description">
                    <div class="title-description">{{lang.get("MAX_STAKE_LIMIT")}}</div>
                    <div class="value-description">
                      {{ pool.totalMaxStake.toLocaleString() }}
                    </div>
                  </div>
                  <div class="description">
                    <div class="title-description">{{lang.get("LOCK_IN")}}</div>
                    <div class="value-description">{{isPoolOpen(pool) ? "Open" : "Closed"}}</div>
                  </div>
                  <div class="description">
                    <div class="title-description">{{lang.get("NETWORK")}}</div>
                    <div class="value-description">{{lang.get("ETHEREUM")}}</div>
                  </div>
                </div>
                <div v-if="poolStrength(pool) >= 100" class="container-button">
                  <button
                    class="button button_pull-full"
                    data-cy="button_pull-full"
                  >
                    <i class="i-check-full"></i>
                    {{lang.get("POOL_FULL")}}
                  </button>
                </div>
                <div v-else-if="!isPoolOpen(pool)" class="container-button">
                  <button disabled
                    class="button button_pull-full"
                    data-cy="button_pull-full"
                  >
                    <i class="i-check-full"></i>
                    {{lang.get("STAKE_ENDED")}}
                  </button>
                </div>
                <div
                  v-else-if="poolStrength(pool) < 100 && (farmTokenBalance > 0) && (stakeTokenBalance < Number(stakeAmount) || stakeTokenBalance < 0.00000001)"
                  class="container-button"
                >
                  <button
                    disabled
                    class="button button_stake-now"
                    data-cy="button_stake-now"
                  >
                    {{lang.get("STAKE_NOW")}}
                  </button>
                  <a :href="`https://pancakeswap.finance/add/BNB/${getFarmTokens(pool) ? getFarmTokens(pool)[0].address : ''}`" target="_blank">
                  <button
                    class="button button_buy-matic"
                    data-cy="button_buy-matic"
                  >
                    <i class="i-label" style="color:inherit; mergin-right:8px; font-size:18px"></i>
                    {{lang.get("GET")}}&nbsp;{{ tokenTicker(pool.name) }}
                  </button>
                  </a>
                  <div class="addinfo">
                      <i class="i-attention"></i>
                      <span>You can get liquidity tokens using the link above! You need to have both BNB and tokens of the relevant project in order to do so.</span>
                  </div>
                </div>
                <div
                  v-else-if="poolStrength(pool) < 100 && (Number(farmTokenBalance) === 0) && (stakeTokenBalance < Number(stakeAmount) || stakeTokenBalance < 0.00000001)"
                  class="container-button"
                >
                  <a :href="`https://pancakeswap.finance/swap?inputCurrency=${getFarmTokens(pool) ? getFarmTokens(pool)[0].address : ''}`" target="_blank">
                  <button
                    class="button button_stake-now"
                    data-cy="button_buy-matic"
                  >
                    {{lang.get("BUY")}}&nbsp;{{ getFarmTokens(pool) ? tokenTicker(getFarmTokens(pool)[0].name) : ''}}
                  </button>
                  </a>
                  <div class="addinfo">
                  <i class="i-attention"></i>
                  <span>To get LP tokens you need to have both BNB and tokens of the relevant project. Follow the link above to make your tokens' purchase!</span>
                </div>
                </div>
                <div
                  v-else-if="poolStrength(pool) < 100 && stakeTokenBalance >= Number(stakeAmount)"
                  class="container-button"
                >
                  <button
                    @click="stake(pool)"
                    class="button button_stake-now"
                    data-cy="button_stake-now-2"
                  >
                    {{lang.get("STAKE_NOW")}}
                  </button>
                  <div class="addinfo">
                  <span>Stake your Liquidity Tokens and get rewarded in BRNG and the tokens of other partnering projects.</span>
                </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ethers } from "ethers";
import MultiLang from "@/core/multilang";
import TokenSearch from "../../../components/modalWindows/TokenSearch.vue";
import { tokenAbi } from "../../../abi/tokenAbi.js";
import { mapState } from "vuex";
import TransactionStatus from "../../../components/modalWindows/TransactionStatus.vue";
import ShowAddInfoModal from "../../../components/modalWindows/ShowAddInfoModal.vue";
import NewStake from "../../../components/modalWindows/NewStake.vue";
import CabinetNavigation from "@/components/CabinetNavigation";
import Loader from "../../../components/Loader.vue";
import Config from "../../../../Config.json";


export default {
  components: {
    TokenSearch,
    TransactionStatus,
    NewStake,
    CabinetNavigation,
    Loader,
    ShowAddInfoModal,
  },
  name: 'Pool',
  data: function () {
    return {
      lang: new MultiLang(this),

      tokenAddress: "",
      stakeTokenContract: null,
      farmTokenContract: null,
      selectedToken: "",
      stakeTokenBalance: 0,
      farmTokenBalance: 0,
      tokenDecimals: 0,
      stakeAmount: "",
      lowBalance: false,
      incorrectAmount: false,
      showAnimation: true,
      selectPool: false,
      showPoolLimitExceedError: false,
      minStakeAmount: false,
      
      showAllPoolStatsModal: false,
      showTokensList: false,
      showStakeWindow: false,
      showPendingStake: false,
      showPendingApprove: false,
      showSuccessStake: false,
      showSuccessApprove: false,
      showLoader: false,
      showError: false,
      showPools: false,
      showPoolLimitReached: false,
      showInputError: false,
      showAddInfoModal: false,

      pools: [],
      poolData: null,
      farmsTokens: [],
      poolsTotalStakeData: {},
      validReferrer: false
    };
  },
  mounted() {
    this.lang.init();
    let _this = this;
    // setTimeout(function checkIfTotalStakeAvailable(){
    //   if(_this.currentAddress == '0x0000000000000000000000000000000000000000' && _this.tokensTotalStake.V1){
    //     _this.showAllPoolStatsModal = true
    //     _this.showAnimation = false
    //   }else {
    //     setTimeout(checkIfTotalStakeAvailable, 500)
    //   }
    // }, 1000)

    const number = document.getElementById('number');

    // Listen for input event on numInput.
    number.onkeydown = function(e) {
        if(!((e.keyCode > 95 && e.keyCode < 106)
          || (e.keyCode > 47 && e.keyCode < 58) 
          || e.keyCode === 8
          || e.keyCode === 37 || e.keyCode === 39
          || e.keyCode === 188 || e.keyCode === 190
          )) {
            return false;
        }
    }
    setTimeout(async function updateComponentData() {
      try {
        await _this.getUserBalance()

        setTimeout(updateComponentData, 10000)
      } catch (error) {
        setTimeout(updateComponentData, 300)
      }
    })
  },

  computed: {
    ...mapState(["currentAddress", "stakeTokens", "tokensTotalStake"]),
    tokenImg() {
      //TODO check the address and set the proper class with icon
    },
    
    poolsNoWallet() {
      return Config.tokens
    },
    
    maxStake() {
      if(this.poolData) {
        return this.poolData.totalMaxStake - this.totalPoolStaking
      }
    }
  },

  methods: {
    poolEndsIn(pool){
      const currentDate = Math.floor(new Date().getTime() / 1000)
      const daysPassed = Math.floor((currentDate - Config.CONTRACT_VERSION_CONFIG[pool.poolVersion].POOL_START_TIME) / 3600 / 24)

      return Config.CONTRACT_VERSION_CONFIG[pool.poolVersion].STAKE_DAYS - daysPassed
    },
    getFarmTokens(pool) {
      if (this.stakeTokens && this.selectedToken) {
        let stakeTokenData = this.stakeTokens.filter(
          (token) => token.name === this.selectedToken && token.poolVersion === pool.poolVersion
        );


        return stakeTokenData[0].farmTokensList;
      }else {
        return false
      }
    },
    isPoolOpen(pool){
      let currentTime = Math.floor(new Date().getTime() / 1000)
      return Boolean(currentTime < Config.CONTRACT_VERSION_CONFIG[pool.poolVersion].POOL_END_TIME)
    },

    async setupToken(token) {

      this.poolData = null;
      this.stakeAmount = "";
      this.lowBalance = false
      this.incorrectAmount = false
      this.showPoolLimitExceedError = false
      this.minStakeAmount = false
      this.showPools = false
      if(this.tokensTotalStake.V1 === undefined){
            this.$root.core.getSiteData()
      }
      const stakeTokenAddress = token.target.attributes.value.value;
      this.selectedToken = token.target.attributes.name.value;

      const {address: farmTokenAddress } = Config.stakeTokens.find(el => el.address === stakeTokenAddress).farmTokensList[0]


      if (this.pools.length != 0) {
        if (this.pools[0].address != stakeTokenAddress) {
          this.pools = [];
        }
      }

      try {
        // const totalStake = await _this.$root.core.getTotalStaking(token)
        // _this.$store.commit('setTotalStake', totalStake)
        this.stakeTokenContract = new ethers.Contract(
          stakeTokenAddress,
          tokenAbi,
          this.$root.core.providerAddress
        ).connect(this.currentAddress != "0x0000000000000000000000000000000000000000" ? this.$root.core.signer : this.$root.core.providerAddress);
        this.farmTokenContract = new ethers.Contract(
          farmTokenAddress,
          tokenAbi,
          this.$root.core.providerAddress
        ).connect(this.currentAddress != "0x0000000000000000000000000000000000000000" ? this.$root.core.signer : this.$root.core.providerAddress);
        
        this.tokenAddress = stakeTokenAddress;

        await this.getUserBalance()
        
        
        this.pools = this.stakeTokens.filter(
            (pool) => pool.address.toLowerCase() === this.tokenAddress.toLowerCase()
          ).sort((a,b) => b.id - a.id);
      } catch (error) {
        console.log(error);
      }
    },

    redirectToStake(){
      this.$root.core.updateUserInfo(this.currentAddress, 20000)
      setTimeout(()=> this.$router.push({name: 'stake'}), 2000)
    },
    
 
    async getUserBalance(){

        let resultStakeTokenBalance = await this.stakeTokenContract.balanceOf(this.currentAddress);
        let resultFarmTokenBalance = await this.farmTokenContract.balanceOf(this.currentAddress);

        let stakeTokenDecimals = Config.tokens.find(el => el.address.toLowerCase() === this.tokenAddress.toLowerCase()).decimals
        const {decimals: farmTokenDecimals } = Config.stakeTokens.find(el => el.address === this.tokenAddress).farmTokensList[0]

        if(this.tokenAddress && (this.tokenAddress.toLowerCase() === "0xf74ee1e10e097dc326a2ad004f9cc95cb71088d3" || this.tokenAddress.toLowerCase() === "0x21b64d891805b0c6437e8209146e60ad87ebb499")){
            this.stakeTokenBalance = this.$root.core.withoutRound(
            ethers.utils.formatUnits(resultStakeTokenBalance._hex, stakeTokenDecimals), 8
          );
            this.farmTokenBalance = this.$root.core.withoutRound(
            ethers.utils.formatUnits(resultFarmTokenBalance._hex, farmTokenDecimals),
          );

        }else{
          this.stakeTokenBalance = this.$root.core.withoutRound(
          ethers.utils.formatUnits(resultStakeTokenBalance._hex, stakeTokenDecimals)
        );
          this.farmTokenBalance = this.$root.core.withoutRound(
            ethers.utils.formatUnits(resultFarmTokenBalance._hex, farmTokenDecimals),
          );
        }
    },

    async stake(poolData) {
      this.poolData = poolData
      if (
        Number(this.stakeAmount) > Number(this.stakeTokenBalance) && Number(this.stakeAmount) > 0
      ) {
        this.lowBalance = true;
      } else if (
        Number(this.stakeAmount) === 0){
        this.incorrectAmount = true
      }else {
        if(this.stakeAmount > this.poolData.totalMaxStake - this.totalPoolStaking){
          this.stakeAmount = this.poolData.totalMaxStake - this.totalPoolStaking;
        }else if (this.stakeAmount < this.poolData.userMinStake){

          this.minStakeAmount = true
          return
        }
        this.showStakeWindow = true;
      }
    },

   async  setMaxValue() {

      if(this.poolData && this.stakeTokenBalance >  this.poolData.totalMaxStake - this.totalPoolStaking){
        this.stakeAmount = this.poolData.totalMaxStake - this.totalPoolStaking;
      }else {
        this.stakeAmount = this.stakeTokenBalance;
      }
      
      await this.getTokensRates()
    },

    async getTokensRates() {
      try {
          if (this.tokenAddress) {
        let V1TotalStake = this.tokensTotalStake.V1[this.tokenAddress.toLowerCase()]
        let V2TotalStake = this.tokensTotalStake.V2[this.tokenAddress.toLowerCase()] 
        this.poolsTotalStakeData = {
          V1TotalStake,
          V2TotalStake
        }
        
          const pools = this.stakeTokens.filter(
            (pool) => pool.address.toLowerCase() === this.tokenAddress.toLowerCase()
          );

          for(let i = 0; i < pools.length; i++) {
            if(pools[i].referrerStatus === undefined){
              const referrerAddress = localStorage.getItem('ref') || ""

              if(referrerAddress.length === 42 && referrerAddress !== this.currentAddress){
                const res = await this.$root.core.checkReferrer(referrerAddress, pools[i].poolVersion);
                pools[i]['referrerStatus'] = res
              }else {
                pools[i]['referrerStatus'] = false
              }
              
            }
          }
          const currentDate = Math.floor(new Date().getTime() / 1000)
          pools.forEach((poolData, idx) => {
              
              for (let i = 0; i < poolData.farmTokensList.length; i++) {

              const userHourlyReard = (Number(this.stakeAmount) * poolData.farmTokensList[i].hourlyReward) / (this.poolsTotalStakeData[`${poolData.poolVersion}TotalStake`] + Number(this.stakeAmount))
              const currentDate = Math.floor(new Date().getTime() / 1000)
              const daysPassed = Math.floor((currentDate - Config.CONTRACT_VERSION_CONFIG[poolData.poolVersion].POOL_START_TIME) / 3600 / 24)

              const possibleReward =
                Number(userHourlyReard * 24) *
                ((Config.CONTRACT_VERSION_CONFIG[poolData.poolVersion].STAKE_DAYS - daysPassed) - poolData.farmTokensList[i].interval);

              if(poolData.referrerStatus){
                poolData.farmTokensList[i].possibleReward = Number(possibleReward) * 0.95
              }else {
                poolData.farmTokensList[i].possibleReward = Number(possibleReward) * 0.9
              }
              
              } 

              poolData.poolTotalStake = this.poolsTotalStakeData[`V${poolData.poolVersion}TotalStake`];
              
          });

          //reseting the array so that data has changed
          
          this.pools = pools.sort((a,b) => b.id - a.id);

      }
      } catch (error) {
        console.log(error);
      }

          
    },

    async findPools() {
      if(!this.selectedToken && !this.stakeAmount) {
        this.selectPool = true;
        this.showAnimation = false
        this.showAllPoolStatsModal = false
        return
      }else if(this.selectedToken && !this.stakeAmount){
        this.showInputError = true
        return
      }
      this.showAnimation = false;
      this.showAllPoolStatsModal = false

      if (this.selectedToken && this.stakeAmount) {
        if (
          Number(this.stakeAmount) > Number(this.stakeTokenBalance) ||
          Number(this.stakeTokenBalance) === 0
        ) {
          this.lowBalance = true;
          //  this.pools = Config.stakeTokens.filter(
          // (pool) => pool.name === this.selectedToken);
          
        }
        // if(Number(this.stakeAmount) > this.pools[0].totalMaxStake - this.totalPoolStaking){
        //   this.stakeAmount = this.pools[0].totalMaxStake - this.totalPoolStaking;
        //   this.showPoolLimitExceedError = true
        // }
        // if(Number(this.stakeAmount) < this.pools[0].userMinStake){
        //   this.minStakeAmount = true;
        // }

        // this.pools = Config.stakeTokens.filter(
        //   (pool) => pool.name === this.selectedToken
        // );
        
         this.selectPool = false 
        //  this.getTokensRates()
         this.showPools = true
      }
    },
    checkBalance() {
      this.incorrectAmount = false
      this.showPoolLimitExceedError = false
      this.minStakeAmount = false
      this.showInputError = false


      if(Number(this.stakeAmount) > Number(this.stakeTokenBalance)){
        this.lowBalance = true;
      }else {
        this.lowBalance = false
      }

      if(this.poolData && Number(this.stakeAmount) > (this.poolData.totalMaxStake - this.totalPoolStaking)){
        this.showPoolLimitExceedError = true
      }

      if(this.poolData && Number(this.stakeAmount) < this.poolData.userMinStake){
        this.minStakeAmount = true
      }
    },
    poolStrength(pool) {
      const poolAddress = pool.address.toLowerCase()
      const totalMaxStake = Config.stakeTokens.find(token => token.address.toLowerCase() === poolAddress.toLowerCase() && pool.poolVersion === token.poolVersion).totalMaxStake;
      const poolTotalStake = this.tokensTotalStake[pool.poolVersion][poolAddress]

     const res = (poolTotalStake / totalMaxStake) * 100 > 100
        ? 100
        : (poolTotalStake / totalMaxStake) * 100;
        return this.$root.core.withoutRound(res)
    },
    poolAPYPercentage(pool){

      if(pool.poolTotalStake === 0){
        return 'N/A'
      }else {
        const poolPercent  = pool.poolVersion === "V1" ? 150 : 60;
        const poolAddress = pool.address.toLowerCase()
        const poolTotalStake = this.tokensTotalStake[pool.poolVersion][poolAddress]
        const resCoef = (poolPercent * pool.coefTokens / poolTotalStake);

        return this.$root.core.withoutRound(resCoef) === "0.00" ? "N/A" : this.$root.core.withoutRound(resCoef);

      }
      
    },
    getPoolStrengthNoWallet(pool){
      const poolAddress = pool.address.toLowerCase()
      const totalMaxStake = Config.stakeTokens.find(token => token.address === poolAddress && pool.poolVersion === token.poolVersion).totalMaxStake;

      const res =  this.tokensTotalStake[pool.poolVersion][poolAddress] / totalMaxStake * 100  > 100
      ? 100
      : (this.tokensTotalStake[pool.poolVersion][poolAddress] / totalMaxStake * 100);

      return this.$root.core.withoutRound(res)
    },
    poolAPYPercentageNoWallet(pool){
      const poolAddress = pool.address.toLowerCase()
      const coefTokens = Config.stakeTokens.find(token => token.address === poolAddress && pool.poolVersion === token.poolVersion).coefTokens
      const poolTotalStake = this.tokensTotalStake[pool.poolVersion][poolAddress]

      const resCoef = (150 * coefTokens / poolTotalStake);

      return this.$root.core.withoutRound(resCoef) === "0.00" ? "N/A" : this.$root.core.withoutRound(resCoef);
    },

    getFarmTokensForPoolNoWallet(pool){
        return Config.stakeTokens.find(el => pool.address.toLowerCase() === el.address.toLowerCase()).farmTokensList
    },

    tokenTicker(tokenName) {
      return Config.tokenAliases[tokenName];
    }
}
};
</script>